import React from 'react';
import { Spin, Space } from 'antd';

const RedirectScreen = () => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <Spin size="large" />
      <h2 className="text-2xl font-semibold text-gray-11 mt-6 tracking-wider">Loading</h2>
    </div>
  );
};

export default RedirectScreen;
