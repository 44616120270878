import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { RtmChannel, RtmClient } from 'agora-rtm-sdk';
import React, { createContext, useContext, useReducer } from 'react';
import { Socket } from 'socket.io-client';
import { BreakoutRoomType } from 'src/hooks/useCreateBreakoutRoom';
import GlobalReducer from './GlobalReducer';
import {
  CLOSE_PRIVATE_CHAT,
  OPEN_PRIVATE_CHAT,
  UPDATE_ACTIVE_BREAKOUT_GROUPS,
  UPDATE_ACTIVE_LIVE_ACTIVITY,
  UPDATE_ACTIVITY_PROGRESS,
  UPDATE_ATTENDANCE_LIST,
  UPDATE_CLASS_ACTIVITIES,
  UPDATE_CURRENT_PRESENTER,
  UPDATE_EXTERNAL_LINK_PROGRESS,
  UPDATE_HOST_AND_MYID,
  UPDATE_LAUNCHED_RESOURCE,
  UPDATE_LESSON,
  UPDATE_LIVE_CLASS,
  UPDATE_LIVE_USERS,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANTS,
  UPDATE_PARTICIPANT_FIELDS,
  UPDATE_PRIVATE_VIDEO_CHAT_USER_UID,
  UPDATE_PUBLISHED_RESOURCE,
  UPDATE_RTC_CLIENT,
  UPDATE_RTM_CHANNEL,
  UPDATE_RTM_CLIENT,
  UPDATE_SELECTED_CLASSES,
  UPDATE_SELECTED_CLASS_ACTIVITY,
  UPDATE_SOCKET_CLIENT,
  UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP,
  UPDATE_VIRTUAL_BG,
} from './Types';

type LiveUser = {
  type: string;
  userId: string | number;
  channelName: string;
  firstName: string;
  lastName: string;
  avatar: string;
  isMuted: boolean;
  hasVideoOn: boolean;
  isHost: boolean;
};

type LiveClassAttendance = {
  _id: string;
  computedStatus: string;
  status: string;
  user: string;
  student: {
    class: string;
    lastName: string;
    firstName: string;
    avatar: string;
    _id: string;
  };
  date: string;
};

export interface IParticipant {
  type: 'teacher' | 'ta' | 'student' | 'CoTeacher';
  userId: string;
  uid: string | number;
  channelName?: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  lastEmojiSent: string;
  isMuted: boolean;
  isPresenter: boolean;
  hasVideoOn: boolean;
  isHost: boolean;
  isCoHost?: boolean;
  isMuteLocked: boolean;
  handIsRaised: boolean;
  isOnline: boolean;
  shouldRespondToAttendance?: boolean;
}

export interface IParticipants {
  [id: string]: IParticipant;
}

export interface IClassActivity {
  isAssignment: boolean;
  isPlaylist: boolean;
  isExitTicket: boolean;
  isExam: boolean;
  gradePoints: number;
  assignmentPoints: number;
  gamePoints: number;
  gradedActivity: boolean;
  learnerGroups: any[];
  state: string;
  questions: string[];
  _id: string;
  learnerGroup: any[];
  title: string;
  type: string;
  lesson: string;
  dueDate: string | Date;
  classes: any[];
  materials: any[];
  createdAt: string | Date;
  updatedAt: string | Date;
  account: string;
  isLaunched: boolean;
  instruction: string;
  activityUrl: string;
}

export interface IClassActivities {
  [id: string]: IClassActivity;
}

export interface ActivityProgress {
  [activityId: string]: {
    progression: {
      [studentId: string]: {
        [questionId: string]: boolean | undefined;
      };
    };
    studentEngagement: {
      completed: any[];
      incompleted: [];
    };
    questionStats: {
      [questionId: string]: {
        // correctAns: number;
        // incorrectAns: number;
        [studentId: string]: boolean;
      };
    };
    nearPod: string[];
    pearDeck: string[];
    externalLink: string[];
  };
}

export interface ActiveLiveActivity {
  liveClass: any;
  classActivity: {
    lesson: string;
    questions?: string[];
    type: string;
    _id: string;
    activityUrl?: string;
  };
}

type LiveClass = {
  channelName: string;
  type: string;
  lesson: string;
};

export type VirtualBG = {
  url: string | undefined;
  type: 'image' | 'blur' | 'clear';
};

export interface ExternalLinkProgressType {
  [key: string]: any[];
}

export type StateType = {
  hostID: string | number | undefined;
  myID: string | number | undefined;
  liveUsers: LiveUser[] | [];
  openChat: boolean;
  selectedPrivateUserID: number | string | null;
  privateVideoChatUserUID: string | undefined;
  rtcClient?: IAgoraRTCClient;
  rtmClient?: RtmClient;
  rtmChannel?: RtmChannel;
  socket?: Socket;
  liveClass?: any;
  lesson?: any;
  participants: IParticipants;
  classActivities?: IClassActivities;
  selectedClassActivity: IClassActivity | undefined;
  activityProgress: ActivityProgress;
  publishedResources: string[];
  attendanceList: LiveClassAttendance[];
  currentPresenter: null | number | string;
  userConnectedToBreakoutGroup: boolean | undefined;
  externalLinkProgress: ExternalLinkProgressType;
  updateLiveUsers?: (users: any[]) => void;
  updateSelectedClasses?: (classIds: string[]) => void;
  updateHostAndMyId?: (myID: string | number, hostID: string | number) => void;
  updateRTCClient?: (rtcClient: IAgoraRTCClient) => void;
  updateRTMClient?: (rtmClient: RtmClient) => void;
  updateRTMChannel?: (rtmChannel: RtmChannel) => void;
  updateSocketClient?: (socket: Socket) => void;
  openPrivateChat?: (selectedPrivateUserID: number | string) => void;
  closePrivateChat?: () => void;
  updateLiveClass?: (liveClass: any) => void;
  updateLesson?: (liveClass: any) => void;
  updateParticipants?: (participants: IParticipants) => void;
  updateParticipant?: (participant: IParticipant) => void;
  updateParticipantFields?: (fields: Partial<IParticipant>) => void;
  updateClassActivities?: (classActivities: IClassActivities) => void;
  updateSelectedClassActivity?: (classActivity: IClassActivity | undefined | null) => void;
  updatePrivateChatUserUID?: (uid: string | undefined) => void;
  updatePublishResources?: (resources: string[]) => void;
  updateAttendanceList?: (resources: LiveClassAttendance[]) => void;
  updateActivityProgress?: (activityProgress: ActivityProgress) => void;
  updateVirtualBg?: (virtualBG: VirtualBG | null) => void;
  updateActiveLiveActivity?: (data: ActiveLiveActivity | undefined) => void;
  updateActiveBreakoutGroups?: (isOpen: boolean, selectedGroups: BreakoutRoomType[]) => void;
  isActiveBreakoutGroup: boolean;
  selectedBreakoutGroups: BreakoutRoomType[];
  updateLaunchedResource?: (resource: string | undefined) => void;
  virtualBG: VirtualBG | null;
  selectedClasses?: any[];
  activeLiveActivity: ActiveLiveActivity | undefined;
  launchedResource: string | undefined;
  updateCurrentPresenter?: (presenterId: null | string | number) => void;
  updateUserConnectedToBreakoutGroup?: (conncted: boolean | undefined) => void;
  updateExternalLinkProgress?: (data: ExternalLinkProgressType) => void;
};

export type Action = {
  type:
    | 'UPDATE_HOST_AND_MYID'
    | 'UPDATE_LIVE_USERS'
    | 'OPEN_PRIVATE_CHAT'
    | 'CLOSE_PRIVATE_CHAT'
    | 'UPDATE_RTC_CLIENT'
    | 'UPDATE_RTM_CLIENT'
    | 'UPDATE_RTM_CHANNEL'
    | 'UPDATE_SOCKET_CLIENT'
    | 'UPDATE_LIVE_CLASS'
    | 'UPDATE_PARTICIPANTS'
    | 'UPDATE_PARTICIPANT'
    | 'UPDATE_CLASS_ACTIVITIES'
    | 'UPDATE_LESSON'
    | 'UPDATE_PUBLISHED_RESOURCE'
    | 'UPDATE_ATTENDANCE_LIST'
    | 'UPDATE_PRIVATE_VIDEO_CHAT_USER_UID'
    | 'UPDATE_SELECTED_CLASS_ACTIVITY'
    | 'UPDATE_ACTIVITY_PROGRESS'
    | 'UPDATE_PARTICIPANT_FIELDS'
    | 'UPDATE_VIRTUAL_BG'
    | 'UPDATE_ACTIVE_LIVE_ACTIVITY'
    | 'UPDATE_ACTIVE_BREAKOUT_GROUPS'
    | 'UPDATE_LAUNCHED_RESOURCE'
    | 'UPDATE_CURRENT_PRESENTER'
    | 'UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP'
    | 'UPDATE_EXTERNAL_LINK_PROGRESS'
    | 'UPDATE_SELECTED_CLASSES';
  payload?: any;
};

export const initialState: StateType = {
  hostID: undefined,
  myID: undefined,
  liveUsers: [],
  selectedPrivateUserID: null,
  privateVideoChatUserUID: undefined,
  selectedClassActivity: undefined,
  openChat: false,
  participants: {},
  publishedResources: [],
  attendanceList: [],
  activityProgress: {},
  selectedClasses: [],
  virtualBG: null,
  activeLiveActivity: undefined,
  isActiveBreakoutGroup: false,
  selectedBreakoutGroups: [],
  launchedResource: undefined,
  currentPresenter: null,
  userConnectedToBreakoutGroup: undefined,
  externalLinkProgress: {},
  liveClass: {},
};

export const GlobalContext = createContext(initialState);

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider: React.FC<{
  children: React.ReactNode;
  value?: Partial<typeof initialState>;
}> = ({ children, value }) => {
  const [state, dispatch] = useReducer(GlobalReducer, { ...initialState, ...value });

  function updateSelectedClasses(classIds: string[]) {
    dispatch({
      type: UPDATE_SELECTED_CLASSES,
      payload: classIds,
    });
  }

  function updateLiveUsers(users: LiveUser[]) {
    dispatch({
      type: UPDATE_LIVE_USERS,
      payload: users,
    });
  }

  function updateHostAndMyId(myID: string | number, hostID: string | number) {
    dispatch({
      type: UPDATE_HOST_AND_MYID,
      payload: {
        myID,
        hostID,
      },
    });
  }

  /** this function will be removed in future */
  function openPrivateChat(selectedPrivateUserID: number | string) {
    dispatch({
      type: OPEN_PRIVATE_CHAT,
      payload: selectedPrivateUserID,
    });
  }
  /** this function will be removed in future */
  function closePrivateChat() {
    dispatch({
      type: CLOSE_PRIVATE_CHAT,
    });
  }

  function updatePrivateChatUserUID(uid: string | undefined) {
    dispatch({
      type: UPDATE_PRIVATE_VIDEO_CHAT_USER_UID,
      payload: uid,
    });
  }

  function updateRTCClient(rtcClient: IAgoraRTCClient) {
    dispatch({
      type: UPDATE_RTC_CLIENT,
      payload: rtcClient,
    });
  }

  function updateRTMClient(rtmClient: RtmClient) {
    dispatch({
      type: UPDATE_RTM_CLIENT,
      payload: rtmClient,
    });
  }

  function updateRTMChannel(rtmChannel: RtmChannel) {
    dispatch({
      type: UPDATE_RTM_CHANNEL,
      payload: rtmChannel,
    });
  }

  function updateSocketClient(socket: Socket) {
    dispatch({
      type: UPDATE_SOCKET_CLIENT,
      payload: socket,
    });
  }

  function updateLiveClass(liveClass: any) {
    dispatch({
      type: UPDATE_LIVE_CLASS,
      payload: liveClass,
    });
  }

  function updateParticipants(participants: IParticipants) {
    dispatch({
      type: UPDATE_PARTICIPANTS,
      payload: participants,
    });
  }

  function updateParticipant(participant: IParticipant) {
    dispatch({
      type: UPDATE_PARTICIPANT,
      payload: participant,
    });
  }

  function updateParticipantFields(fields: Partial<IParticipant>) {
    dispatch({
      type: UPDATE_PARTICIPANT_FIELDS,
      payload: fields,
    });
  }

  function updateClassActivities(classActivities: IClassActivities) {
    dispatch({
      type: UPDATE_CLASS_ACTIVITIES,
      payload: classActivities,
    });
  }

  function updateSelectedClassActivity(activity: IClassActivity | undefined | null) {
    dispatch({
      type: UPDATE_SELECTED_CLASS_ACTIVITY,
      payload: activity,
    });
  }

  function updateActivityProgress(activityProgress: ActivityProgress) {
    dispatch({
      type: UPDATE_ACTIVITY_PROGRESS,
      payload: activityProgress,
    });
  }

  function updateLesson(lesson: any) {
    dispatch({
      type: UPDATE_LESSON,
      payload: lesson,
    });
  }

  function updatePublishResources(resources: string[]) {
    dispatch({
      type: UPDATE_PUBLISHED_RESOURCE,
      payload: resources,
    });
  }

  function updateActiveLiveActivity(data: ActiveLiveActivity | undefined) {
    dispatch({
      type: UPDATE_ACTIVE_LIVE_ACTIVITY,
      payload: data,
    });
  }

  function updateAttendanceList(attendanceList: LiveClassAttendance[]) {
    dispatch({
      type: UPDATE_ATTENDANCE_LIST,
      payload: attendanceList,
    });
  }

  function updateVirtualBg(virtualBg: VirtualBG | null) {
    dispatch({
      type: UPDATE_VIRTUAL_BG,
      payload: virtualBg,
    });
  }

  function updateActiveBreakoutGroups(isOpen = false, selectedGroups: BreakoutRoomType[] = []) {
    dispatch({
      type: UPDATE_ACTIVE_BREAKOUT_GROUPS,
      payload: {
        isActiveBreakoutGroup: isOpen,
        selectedBreakoutGroups: selectedGroups,
      },
    });
  }

  function updateLaunchedResource(resouce?: string) {
    dispatch({
      type: UPDATE_LAUNCHED_RESOURCE,
      payload: resouce,
    });
  }

  function updateCurrentPresenter(presenterId: null | string | number) {
    dispatch({
      type: UPDATE_CURRENT_PRESENTER,
      payload: presenterId,
    });
  }

  function updateUserConnectedToBreakoutGroup(isConnected: boolean | undefined) {
    dispatch({
      type: UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP,
      payload: isConnected,
    });
  }

  function updateExternalLinkProgress(data: ExternalLinkProgressType) {
    dispatch({
      type: UPDATE_EXTERNAL_LINK_PROGRESS,
      payload: data,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        hostID: state.hostID,
        myID: state.myID,
        liveUsers: state.liveUsers,
        openChat: state.openChat,
        selectedPrivateUserID: state.selectedPrivateUserID,
        privateVideoChatUserUID: state.privateVideoChatUserUID,
        rtcClient: state.rtcClient,
        rtmClient: state.rtmClient,
        rtmChannel: state.rtmChannel,
        socket: state.socket,
        liveClass: state.liveClass,
        participants: state.participants,
        classActivities: state.classActivities,
        selectedClassActivity: state.selectedClassActivity,
        lesson: state.lesson,
        publishedResources: state.publishedResources,
        attendanceList: state.attendanceList,
        activityProgress: state.activityProgress,
        selectedClasses: state.selectedClasses,
        virtualBG: state.virtualBG,
        activeLiveActivity: state.activeLiveActivity,
        selectedBreakoutGroups: state.selectedBreakoutGroups,
        isActiveBreakoutGroup: state.isActiveBreakoutGroup,
        launchedResource: state.launchedResource,
        currentPresenter: state.currentPresenter,
        userConnectedToBreakoutGroup: state.userConnectedToBreakoutGroup,
        externalLinkProgress: state.externalLinkProgress,
        openPrivateChat,
        closePrivateChat,
        updateLiveUsers,
        updateSelectedClasses,
        updateHostAndMyId,
        updateRTCClient,
        updateRTMClient,
        updateRTMChannel,
        updateSocketClient,
        updateLiveClass,
        updateParticipants,
        updateParticipant,
        updateParticipantFields,
        updateClassActivities,
        updateSelectedClassActivity,
        updateActivityProgress,
        updateLesson,
        updatePublishResources,
        updatePrivateChatUserUID,
        updateAttendanceList,
        updateVirtualBg,
        updateActiveLiveActivity,
        updateActiveBreakoutGroups,
        updateLaunchedResource,
        updateCurrentPresenter,
        updateUserConnectedToBreakoutGroup,
        updateExternalLinkProgress,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
