import 'antd/dist/antd.css';
import { AppProps } from 'next/dist/shared/lib/router/router';
import Head from 'next/head';
import ErrorBoundary from 'src/components/primitives/ErrorBoundary';
import { GlobalProvider } from 'src/globalState/GlobalState';
import { AppErrorFallback } from '../components/primitives/ErrorFallback';
import AuthProvider from '../contexts/AuthProvider';
import '../styles/index.css';
// All scss styles imported in one file here
import '../styles/scss/app.styles.scss';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary fallback={<AppErrorFallback />} showDialog>
      <AuthProvider>
        <GlobalProvider>
          <Head>
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />

            <link rel="preconnect" href="https://fonts.gstatic.com"></link>
            <link
              href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <script src="https://cdn.jsdelivr.net/npm/context-filter-polyfill@0.2.4/dist/index.min.js"></script>
          </Head>
          <Component {...pageProps} />
        </GlobalProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
