import { Loading3QuartersOutlined } from '@ant-design/icons';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function Button(props: IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) {
  const {
    size,
    loading,
    variant = 'primary',
    intent = 'default',
    iconBefore: Icon,
    disabled,
    ...rest
  } = props;

  return (
    <StyledButton
      $variant={variant}
      $loading={loading}
      $size={size}
      $intent={intent}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && !disabled && <Loading3QuartersOutlined spin className="mr-8" />}
      {Icon && !loading && <span className="inline-flex items-center mr-8">{Icon}</span>}
      {props.children}
    </StyledButton>
  );
}

// Using $ before the names ensures SC don't pass them to the DOM node
var StyledButton = styled.button<{
  $loading?: boolean;
  $variant?: 'primary' | 'alternate' | 'outline';
  $size?: 'regular' | 'small';
  $intent?: 'default' | 'danger';
}>`
  ${tw`focus:ring ring-primary ring-opacity-48 focus:outline-none px-32px rounded-small`}
  ${tw`focus:shadow-extraLarge hover:shadow-extraLarge flex items-center disabled:opacity-48`}

  ${({ $variant, $intent }) =>
    ['outline', 'alternate'].includes(`${$variant}`)
      ? $intent === 'danger'
        ? tw`text-error border-error`
        : tw`text-primary border-primary`
      : tw`text-white`};
  ${({ $size }) => ($size === 'small' ? tw`h-40px text-14px` : tw`h-48px text-16px font-semibold`)}
  ${({ $loading }) => ($loading ? tw`opacity-48 cursor-wait` : tw`disabled:cursor-not-allowed`)}
  ${({ $variant, $intent }) =>
    $variant === 'outline'
      ? tw`bg-white border`
      : $intent === 'danger'
      ? tw`bg-error`
      : tw`bg-primary`}
    ${({ $variant }) => ($variant === 'alternate' ? tw`bg-opacity-1` : '')}
`;

interface IButtonProps {
  loading?: boolean;
  variant?: 'primary' | 'alternate' | 'outline';
  size?: 'regular' | 'small';
  intent?: 'default' | 'danger';
  iconBefore?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}
