import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import RedirectScreen from '../components/common/RedirectScreen';
import api, { AUTH_SERVICE, getToken } from '../utils/api';

interface IProps {
  children: any;
}

export interface IUser {
  lastLogin: {
    coordinate: unknown[];
  };
  mobileVerified: boolean;
  userType: string;
  districtAccount: unknown[];
  schools: string[];
  emailVerified: boolean;
  roles: string[];
  passwordReset: boolean;
  changePassword: boolean;
  active: boolean;
  tokens: number;
  _id: string;
  email: string;
  __v: number;
  createdAt: string;
  firstName: string;
  lastName: string;
  school: string;
  updatedAt: string;
  account: string;
  emailHash: string;
  cleverId: string;
  agoraId: string;
  grade: string;
  graduationYear: number;
  mobile: string;
  sisId: string;
  chatIdentity: string;
  id: string;
  liveVideoBackground?: {
    type: string | null;
    file: null | Record<string, string>;
  };
}

export type StateType = {
  user: IUser | null;
  token: string;
  updateUser?: (user: any) => void;
};
const initialState: StateType = {
  user: null,
  token: '',
};
export const AuthContext = React.createContext(initialState);

const AuthProvider: FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<any>(null);
  const router = useRouter();
  useEffect(() => {
    const authToken: string | undefined = getToken();
    if (!authToken || typeof authToken == 'undefined') {
      window.location.assign(
        `${process.env.NEXT_PUBLIC_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}`
      );
      return;
    }
    // If there is no cookie set but a but a cookie from the URL Params set the cookie
    if (!getToken()) {
      cookie.set(`${process.env.NEXT_PUBLIC_NODE_ENV}_token`, authToken);
    }

    if (String(authToken) != String(token)) {
      api<any, any>(AUTH_SERVICE, '/users/me?population=["liveVideoBackground.file"]')
        .then((d: any) => {
          setToken(authToken);
          setUser(d.data);
        })
        .catch((e) => {
          setToken(null);
          setUser(null);
        });
    }
  }, [token]);
  const updateUser = (user: any): void | null => {
    setUser(user);
  };

  return (
    <AuthContext.Provider value={{ user, token, updateUser }}>
      {user ? children : <RedirectScreen />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
