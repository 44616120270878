export const UPDATE_HOST_AND_MYID = 'UPDATE_HOST_AND_MYID';
export const UPDATE_LIVE_USERS = 'UPDATE_LIVE_USERS';
export const TOGGLE_PRIVATE_MODAL = 'TOGGLE_PRIVATE_MODAL';
export const OPEN_PRIVATE_CHAT = 'OPEN_PRIVATE_CHAT';
export const CLOSE_PRIVATE_CHAT = 'CLOSE_PRIVATE_CHAT';
export const UPDATE_RTM_CLIENT = 'UPDATE_RTM_CLIENT';
export const UPDATE_RTC_CLIENT = 'UPDATE_RTC_CLIENT';
export const UPDATE_RTM_CHANNEL = 'UPDATE_RTM_CHANNEL';
export const UPDATE_SOCKET_CLIENT = 'UPDATE_SOCKET_CLIENT';
export const UPDATE_LIVE_CLASS = 'UPDATE_LIVE_CLASS';
export const UPDATE_PARTICIPANTS = 'UPDATE_PARTICIPANTS';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const UPDATE_PARTICIPANT_FIELDS = 'UPDATE_PARTICIPANT_FIELDS';
export const UPDATE_CLASS_ACTIVITIES = 'UPDATE_CLASS_ACTIVITIES';
export const UPDATE_SELECTED_CLASS_ACTIVITY = 'UPDATE_SELECTED_CLASS_ACTIVITY';
export const UPDATE_ACTIVITY_PROGRESS = 'UPDATE_ACTIVITY_PROGRESS';
export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_PRIVATE_VIDEO_CHAT_USER_UID = 'UPDATE_PRIVATE_VIDEO_CHAT_USER_UID';
export const UPDATE_PUBLISHED_RESOURCE = 'UPDATE_PUBLISHED_RESOURCE';
export const UPDATE_ATTENDANCE_LIST = 'UPDATE_ATTENDANCE_LIST';
export const UPDATE_SELECTED_CLASSES = 'UPDATE_SELECTED_CLASSES';
export const UPDATE_VIRTUAL_BG = 'UPDATE_VIRTUAL_BG';
export const UPDATE_ACTIVE_LIVE_ACTIVITY = 'UPDATE_ACTIVE_LIVE_ACTIVITY';
export const UPDATE_ACTIVE_BREAKOUT_GROUPS = 'UPDATE_ACTIVE_BREAKOUT_GROUPS';
export const UPDATE_LAUNCHED_RESOURCE = 'UPDATE_LAUNCHED_RESOURCE';
export const UPDATE_CURRENT_PRESENTER = 'UPDATE_CURRENT_PRESENTER';
export const UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP = 'UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP';
export const UPDATE_EXTERNAL_LINK_PROGRESS = 'UPDATE_EXTERNAL_LINK_PROGRESS';
