import Button from './Button';

interface ErrorFallback {
  message?: string;
  description?: string;
  reset?: () => void;
  buttonText?: string;
}

export function AppErrorFallback(props: ErrorFallback) {
  const {
    message = 'Something went wrong',
    description = "Oops! That wasn't supposed to happen. Please bear with us while this is resolved",
    buttonText = 'Reload',
    reset = () => window.location.reload(),
  } = props;

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <img
        src="/img/fallback-error.png"
        alt="cup with spilled liquid content"
        width={300}
        height={300}
      />
      <div className="p-5 flex flex-col items-center max-w-md">
        {message && <p className="font-medium text-dark-text text-3xl text-center">{message}</p>}
        {description && (
          <p className="mt-5 text-body-17 text-center font-medium text-gray-1">{description}</p>
        )}
        <Button className="mt-4" size="small" onClick={reset}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default function ErrorFallback(props: ErrorFallback) {
  const {
    message = 'Something went wrong',
    description = "Oops! That wasn't supposed to happen. Please bear with us while this is resolved",
    buttonText = 'Reset',
    reset,
  } = props;

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-5 md:py-14 flex flex-col items-center max-w-md">
        {message && <p className="font-medium text-dark-text text-3xl text-center">{message}</p>}
        {description && (
          <p className="mt-5 text-body-17 text-center font-medium text-gray-1">{description}</p>
        )}
        {reset && (
          <Button className="mt-4" size="small" onClick={reset}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}
