import axios, { AxiosRequestConfig } from 'axios';
import cookie from 'js-cookie';

enum Service {
  auth = 'auth',
  class = 'class',
  communication = 'communication',
}
export const AUTH_SERVICE = Service.auth;
export const CLASS_SERVICE = Service.class;
export const COMMUNICATION_SERVICE = Service.communication;

export const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get('token');
  console.log('NODE_ENV::', process.env.NEXT_PUBLIC_NODE_ENV);
  return (
    tokenParam || cookie.get(`${process.env.NEXT_PUBLIC_NODE_ENV}_token`) || cookie.get(`token`)
  );
};

async function api<T = any, U = Meta>(
  service: Service,
  url: string,
  method: AxiosRequestConfig['method'] | AxiosRequestConfig['data'] = 'get',
  payload?: AxiosRequestConfig['data'] | Omit<AxiosRequestConfig, 'url' | 'method' | 'data'>,
  axiosRequestConfig?: Omit<AxiosRequestConfig, 'url' | 'method' | 'data'>
): Promise<ApiResponse<T, U>> {
  // if (typeof method == AxiosRequestConfig['data']) {
  //
  // }
  if (typeof method == 'object') {
    payload = { ...method };
    method = 'post';
  }
  const token: string | undefined = getToken();
  if (token) axios.defaults.headers.common['x-auth-token'] = `${token}`;
  try {
    let path;
    switch (service) {
      case Service.class:
        path = process.env.NEXT_PUBLIC_CLASS_API_PATH;
        break;
      case Service.communication:
        path = process.env.NEXT_PUBLIC_COMMUNICATION_API_PATH;
        break;
      default:
        path = '/api/v1';
    }
    url = path + url;
    const { data } = await axios({
      url,
      method,
      data: payload,
      ...axiosRequestConfig,
      baseURL: process.env.NEXT_PUBLIC_BASEURL,
    });
    return data;
  } catch (error: any) {
    // use the server error response if available
    if (error.response) {
      const serverMessage: string = error.response?.data?._meta.error?.message;
      const authUrls: string[] = [];
      if (
        !authUrls.includes(url) &&
        error.response.status === 401 &&
        typeof window !== 'undefined'
      ) {
        window.localStorage.setItem('logout', Date.now().toString());
        window.location.assign(
          `${process.env.NEXT_PUBLIC_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}`
        );
      }

      throw new Error(serverMessage);
    }
    // throw errors that happen in the browser as is
    throw new Error(error.message);
  }
}

type ApiResponse<T, U> = {
  data?: T;
  _meta: U;
};

type Meta =
  | {
      status_code: number;
      success: boolean;
    }
  | {
      error: {
        code: number;
        message: string;
        statusCode: number;
      };
    };

export default api;
