import { Action, StateType } from './GlobalState';
import {
  UPDATE_HOST_AND_MYID,
  UPDATE_LIVE_USERS,
  OPEN_PRIVATE_CHAT,
  CLOSE_PRIVATE_CHAT,
  UPDATE_RTM_CLIENT,
  UPDATE_RTM_CHANNEL,
  UPDATE_SOCKET_CLIENT,
  UPDATE_LIVE_CLASS,
  UPDATE_PARTICIPANTS,
  UPDATE_RTC_CLIENT,
  UPDATE_CLASS_ACTIVITIES,
  UPDATE_LESSON,
  UPDATE_PRIVATE_VIDEO_CHAT_USER_UID,
  UPDATE_PUBLISHED_RESOURCE,
  UPDATE_ATTENDANCE_LIST,
  UPDATE_SELECTED_CLASS_ACTIVITY,
  UPDATE_ACTIVITY_PROGRESS,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANT_FIELDS,
  UPDATE_SELECTED_CLASSES,
  UPDATE_VIRTUAL_BG,
  UPDATE_ACTIVE_LIVE_ACTIVITY,
  UPDATE_ACTIVE_BREAKOUT_GROUPS,
  UPDATE_LAUNCHED_RESOURCE,
  UPDATE_CURRENT_PRESENTER,
  UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP,
  UPDATE_EXTERNAL_LINK_PROGRESS,
} from './Types';

export default function GlobalReducer(state: StateType, action: Action): StateType {
  let newState = state;
  switch (action.type) {
    case UPDATE_SELECTED_CLASSES:
      if (Array.isArray(action.payload)) {
        newState = { ...state, selectedClasses: action.payload };
      }
      break;
    case UPDATE_LIVE_USERS:
      if (Array.isArray(action.payload)) {
        newState = { ...state, liveUsers: action.payload };
      }
      break;
    case UPDATE_HOST_AND_MYID:
      if (typeof action.payload === 'object') {
        newState = { ...state, ...action.payload };
      }
      break;
    case OPEN_PRIVATE_CHAT:
      if (
        action.payload === null ||
        typeof action.payload === 'number' ||
        typeof action.payload === 'string'
      ) {
        newState = { ...state, openChat: true, selectedPrivateUserID: action.payload };
      }
      break;
    case CLOSE_PRIVATE_CHAT:
      newState = { ...state, openChat: false, selectedPrivateUserID: null };
      break;
    case UPDATE_PRIVATE_VIDEO_CHAT_USER_UID:
      newState = { ...state, privateVideoChatUserUID: action.payload };
      break;
    case UPDATE_RTC_CLIENT:
      newState = { ...state, rtcClient: action.payload };
      break;
    case UPDATE_RTM_CLIENT:
      newState = { ...state, rtmClient: action.payload };
      break;
    case UPDATE_RTM_CHANNEL:
      newState = { ...state, rtmChannel: action.payload };
      break;
    case UPDATE_SOCKET_CLIENT:
      newState = { ...state, socket: action.payload };
      break;
    case UPDATE_LIVE_CLASS:
      newState = { ...state, liveClass: action.payload };
      break;
    case UPDATE_PARTICIPANTS:
      newState = {
        ...state,
        participants: action.payload,
      };
      break;
    case UPDATE_PARTICIPANT:
      newState = {
        ...state,
        participants: { ...state.participants, [action.payload.userId]: action.payload },
      };
      break;
    case UPDATE_PARTICIPANT_FIELDS:
      if (state.participants[action.payload.userId]) {
        newState = {
          ...state,
          participants: {
            ...state.participants,
            [action.payload.userId]: {
              ...state.participants[action.payload.userId],
              ...action.payload,
            },
          },
        };
      }
      break;
    case UPDATE_CLASS_ACTIVITIES:
      newState = { ...state, classActivities: action.payload };
      break;
    case UPDATE_SELECTED_CLASS_ACTIVITY:
      newState = { ...state, selectedClassActivity: action.payload };
      break;
    case UPDATE_ACTIVITY_PROGRESS:
      newState = { ...state, activityProgress: action.payload };
      break;
    case UPDATE_LESSON:
      newState = { ...state, lesson: action.payload };
      break;
    case UPDATE_PUBLISHED_RESOURCE:
      newState = { ...state, publishedResources: action.payload };
      break;
    case UPDATE_ATTENDANCE_LIST:
      newState = { ...state, attendanceList: action.payload };
      break;
    case UPDATE_VIRTUAL_BG:
      newState = { ...state, virtualBG: action.payload };
      break;
    case UPDATE_ACTIVE_LIVE_ACTIVITY:
      newState = { ...state, activeLiveActivity: action.payload };
      break;
    case UPDATE_ACTIVE_BREAKOUT_GROUPS:
      newState = {
        ...state,
        selectedBreakoutGroups: action.payload.selectedBreakoutGroups,
        isActiveBreakoutGroup: action.payload.isActiveBreakoutGroup,
      };
      break;
    case UPDATE_LAUNCHED_RESOURCE:
      newState = { ...state, launchedResource: action.payload };
      break;
    case UPDATE_CURRENT_PRESENTER:
      newState = { ...state, currentPresenter: action.payload };
      break;
    case UPDATE_USER_CONNECTED_TO_BREAKOUT_GROUP:
      newState = { ...state, userConnectedToBreakoutGroup: action.payload };
      break;
    case UPDATE_EXTERNAL_LINK_PROGRESS:
      newState = { ...state, externalLinkProgress: action.payload };
      break;
    default:
      return state;
  }

  return newState;
}
